import React from 'react'
import { DI } from 'modules'

import { Link } from 'react-router-dom'
import { withRouter, matchPath } from 'react-router'
import Component from 'components/component'
import HeaderLangBlock from './header-lang-block'
import { videosList, videosFilter, dashboard } from 'config/routes'

export class HeaderLangItem extends Component {
  state = { langBlock: true, open: false }

  componentDidMount() {
    this.checkOpen()
  }

  componentDidUpdate() {
    this.checkOpen()
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close)
  }

  checkOpen = () => {
    // eslint-disable-next-line
    const [_, currentLocale = 'en'] =
      this.props.location.pathname.match(/^\/(\w{2})/) || []
    const locale = this.props.locale
    const key = `${this.props.locale.code}HasBeenOpened`
    const open = !sessionStorage.getItem(key)

    if (open && currentLocale === locale.code) {
      sessionStorage.setItem(key, open)
      this.setState({ open }, () => {
        setTimeout(() => {
          window.addEventListener('click', this.close)
        }, 500)
      })
    }
  }

  close = () => {
    window.removeEventListener('click', this.close)
    this.setState({
      open: false,
    })
  }

  onMouseEnter = () => {
    this.setState({
      open: true,
    })
  }

  onMouseLeave = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { langBlock } = this.state

    // eslint-disable-next-line
    const [_, currentLocale = 'en'] =
      this.props.location.pathname.match(/^\/(\w{2})/) || []
    const locale = this.props.locale
    const subitem = Boolean(
      matchPath(this.props.location.pathname, videosList) ||
        matchPath(this.props.location.pathname, dashboard) ||
        matchPath(this.props.location.pathname, videosFilter)
    )

    const active = currentLocale === locale.code

    let link = this.props.location.pathname

    link =
      link.replace(/^\/\w{2}/, `/${locale.code}`) + this.props.location.search

    return (
      <li
        className={this.classList(
          'header-lang-item',
          active && subitem && '_subitem',
          this.state.open && '_opened'
        )}
        key={locale.code}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Link
          to={link}
          className={this.classList('header-lang__link', active && '_active')}
          data-locale={locale.code}
        >
          {locale.code}
        </Link>
        {Boolean(langBlock && subitem) && (
          <HeaderLangBlock locale={locale.code} />
        )}
      </li>
    )
  }
}

export default DI()(withRouter(HeaderLangItem))
